import classNames from 'classnames'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Base = styled.textarea({
  fontSize: 16,
  fontFamily: 'inherit',
  color: colors.black,
  border: `1px solid ${colors.greyD1}`,
  backgroundColor: colors.greyL3,
  borderRadius: 4,
  height: '10em',
  width: '100%',
  padding: 16,
  outline: 'none',
  boxSizing: 'border-box',

  '&.-invalid': {
    borderColor: colors.red,
    color: colors.red,
    backgroundColor: colors.redL3
  }
})

interface Props {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (event?: React.FocusEvent<HTMLTextAreaElement>) => void;
  isInvalid?: boolean;
}

export function TextArea ({
  value,
  placeholder,
  onChange,
  onBlur,
  isInvalid
}:Props):ReactElement {
  return (
    <Base
      className={classNames(isInvalid && '-invalid')}
      value={value}
      placeholder={placeholder}
      onChange={event => onChange?.(event.target.value)}
      onBlur={onBlur}
    />
  )
}
