import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { colors, ColorName } from '../config/variables'
import { Icon } from './Icon'

const Base = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  padding: 24,
  borderRadius: 24,
  backgroundColor: colors.blueL3
})

const IconBase = styled.div({
  display: 'flex',
  flex: '0 0 32px',
  fontSize: 32,
  color: colors.blue,
  marginRight: 16
})

const Message = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: 16,
  fontSize: 16,
  lineHeight: 1.5
})

interface Props {
  iconName?: string;
  iconColor?: ColorName;
  children?: ReactNode;
  backgroundColor?: ColorName;
}

export function MessageBox ({
  iconName,
  iconColor = 'blue',
  children,
  backgroundColor = 'blueL3'
}:Props):ReactElement {
  return (
    <Base style={{ backgroundColor: colors[backgroundColor] }}>
      {iconName && <IconBase style={{ color: colors[iconColor] }}><Icon iconName={iconName} /></IconBase>}
      <Message>
        {children}
      </Message>
    </Base>
  )
}
