import { useState } from 'react'
import validator from 'validator'
import axios from 'axios'
import { DateTime } from 'luxon'

export const shopNames = {
  kashiwa: '柏補聴器センター',
  matsudo: '松戸補聴器センター'
} as const

export type ShopName = keyof typeof shopNames

export const timeNames = {
  am: '午前',
  pm: '午後'
} as const

export type TimeName = keyof typeof timeNames

export const subjectNames = {
  first: '初めてのご相談',
  maintenance: '補聴器の調整・メンテナンス・修理',
  other: 'その他のご相談'
} as const

export type SubjectName = keyof typeof subjectNames

interface State {
  token: string | null
  shop: ShopName | null
  id: string;
  name: string;
  subject: string;
  email: string;
  phone: string;
  date: Date | null;
  time: 'am' | 'pm' | null
}

const initialState: State = {
  token: null,
  shop: null,
  id: '',
  name: '',
  subject: '',
  email: '',
  phone: '',
  date: null,
  time: null
}

type StateKey = keyof State

type Errors = {
  // eslint-disable-next-line no-unused-vars
  [key in StateKey]?: string;
}

export function useReservation () {
  const [state, setState] = useState<State>(initialState)
  const [errors, setErrors] = useState<Errors>({})

  const update = (key: StateKey, value: any) => {
    setState(pre => {
      validate(key, value)
      return ({ ...pre, [key]: value })
    })
  }

  const validate = (key?: StateKey, _value?: any): boolean => {
    if (!key) {
      return Object.keys(state).map(key => validate(key as StateKey)).every(v => v)
    }
    const e = {} as Errors
    e[key] = undefined
    const value = _value === undefined ? state[key] : _value
    switch (key) {
      case 'shop':
        if (!['kashiwa', 'matsudo'].includes(value || '')) {
          e.shop = '店舗を選択してください'
        }
        break
      case 'id':
        if (!!state.id && !validator.isNumeric(value)) {
          e.id = 'No.は半角数字で記入してください'
        }
        break
      case 'name':
        if (!value) {
          e.name = 'お名前を記入してください'
        } else if (Array.from(value).length > 100) {
          e.name = '100文字以内で入力してください'
        }
        break
      case 'subject':
        if (!value || Object.keys(subjectNames).indexOf(value) < 0) {
          e.subject = 'ご用件を選択してください'
        }
        break
      case 'email':
        if (!value) {
          e.email = 'メールアドレスを記入してください'
        } else if (!validator.isEmail(value)) {
          e.email = 'メールアドレスの形式が正しくありません'
        }
        break
      case 'phone':
        if (!!value && !/[0-9\-()]{6,20}/.test(value)) {
          e.phone = '電話番号は半角数字とハイフン、括弧のみで記入してください'
        }
        break
      case 'date':
        if (!value) {
          e.date = '予約日を選択してください'
        }
        break
      default: break
    }
    setErrors(pre => ({ ...pre, ...e }))
    return !e[key]
  }

  const fetchToken = () => {
    axios.get('/api/index.php/token').then(res => update('token', res.data.token))
  }

  const submit = () => {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      Object.keys(state).forEach(key => {
        const value = (() => {
          switch (key) {
            case 'date':
              return DateTime.fromJSDate(state[key] as Date).toFormat('yyyy-MM-dd')
            case 'subject':
              return subjectNames[state[key] as SubjectName]
            default:
              return state[key as StateKey]
          }
        })()
        if (value) {
          data.append(key, value as string)
        }
      })

      axios.post('/api/index.php/reserve', data)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return [
    state,
    update,
    errors,
    validate,
    fetchToken,
    submit
  ] as [
    State,
    typeof update,
    typeof errors,
    typeof validate,
    typeof fetchToken,
    typeof submit,
  ]
}
