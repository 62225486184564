import { Example } from './Example'
import { ContactDrawer } from './ContactDrawer'
import { ReserveForm } from './ReserveForm'
import { ContactForm } from './ContactForm'
import { MobileHeader } from './MobileHeader'
import { NewsIndex } from './NewsIndex'
import { HomeNoticeBlock } from './HomeNoticeBlock'

export default {
  Example,
  ContactDrawer,
  ReserveForm,
  ContactForm,
  MobileHeader,
  NewsIndex,
  HomeNoticeBlock
}
