import { DateTime } from 'luxon'
import React, { ReactElement, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { colors } from '../config/variables'
import { Icon } from './Icon'

const Base = styled.div({
  '.react-calendar': {
    minHeight: 500
  },
  '.react-calendar__navigation': {
    display: 'flex',

    '& > button': {
      fontSize: 20,
      lineHeight: 1,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',

      '&:first-child, &:last-child': {
        display: 'none'
      },

      '&:disabled': {
        opacity: 0.3,
        pointerEvents: 'none'
      },

      '&:nth-child(2), &:nth-child(4)': {
        flex: '0 0 32px',
        width: 32,
        height: 32,
        borderRadius: 32,
        color: colors.black,

        '&::before': {
          content: '""',
          display: 'block',
          width: 8,
          height: 8,
          border: '2px solid currentColor',
          borderWidth: '2px 2px 0 0',
          transform: 'translateX(2px) rotate(-135deg)'
        },

        '&:hover': {
          backgroundColor: colors.greyL2
        },

        '&:nth-child(4)': {
          '&::before': {
            transform: 'translateX(-2px) rotate(45deg)'
          }
        }
      }
    }
  },

  '.react-calendar__viewContainer': {
    margin: '24px 0 0'
  },

  '.react-calendar__month-view__weekdays': {
    '> div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 32,
      fontSize: 14,
      color: colors.greyD2,

      '> abbr': {
        textDecoration: 'none'
      }
    }
  },

  '.react-calendar__tile': {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    padding: 4,

    abbr: {
      display: 'none'
    },

    '&:disabled': {
      pointerEvents: 'none',
      '& > *': {
        opacity: 0.4
      }
    },

    '&.react-calendar__month-view__days__day--neighboringMonth > *': {
      opacity: 0.4
    },

    '&.-weekend': {
      backgroundColor: colors.greyL3
    }
  }
})

const Title = styled.div({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: colors.greyL3,
  color: colors.black,
  fontSize: 16,
  lineHeight: 1.5,
  padding: 8,
  borderRadius: 4,
  margin: '0 0 32px',
  '@media screen and (max-width: 768px)': {
    margin: '0 0 16px'
  }
})

const DateMarker = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  padding: 6,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  borderRadius: 6,

  '> ._icon': {
    fontSize: 24
  },

  '._ng': {
    display: 'none'
  },

  'button:disabled > &': {
    '._ok': {
      display: 'none'
    },
    '._ng': {
      display: 'block'
    }
  },

  ':hover': {
    backgroundColor: colors.blueL3
  },

  '.react-calendar__tile--active > &': {
    backgroundColor: colors.blue,
    color: colors.white
  },

  '.react-calendar__tile--now > &': {
    outline: `4px solid ${colors.blueL2}`
  }
})

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: 32,

  '@media screen and (max-width: 768px)': {
    marginTop: 16
  }
})

interface Props {
  show?: boolean
  title?: string
  value?: Date
  holidays?: string[]
  onChange?: (value: string) => void
  onSubmit?: (value: Date) => void
  onCancel?: () => void
}

export function DatePicker ({
  show,
  title = 'ご予約希望日を選択してください',
  value,
  onChange,
  onSubmit,
  onCancel,
  holidays
}:Props):ReactElement {
  const [localValue, setLocalValue] = useState<Date|undefined>()
  const minDate = DateTime.local().plus({ days: 1 }).toJSDate()
  const maxDate = (() => {
    const d = new Date()
    d.setDate(d.getDate() + (7 * 12))
    return d
  })()

  useEffect(() => {
    const d = value || undefined
    setLocalValue(d)
  }, [show, value])

  return (
    <Base>
      <Title>{title}</Title>
      <Calendar
        calendarType="Hebrew"
        value={localValue}
        view="month"
        prevLabel=""
        nextLabel=""
        tileDisabled={({ date }) => holidays?.includes(DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')) || false}
        tileContent={(arg) => {
          return (
            <DateMarker>
              <span className="_label">{arg.date.getDate().toString()}</span>
              <div className="_icon">
                <Icon iconName="circle" className="_ok" />
                <Icon iconName="minus" className="_ng" />
              </div>
            </DateMarker>
          )
        }}
        minDate={minDate}
        maxDate={maxDate}
        tileClassName={({ date }) => [0, 6].includes(date.getDay()) ? '-weekend' : ''}
        formatDay={(_locale, date) => date.getDate().toString()}
        onChange={(date: Date) => setLocalValue(date)}
      />
      <Actions>
        <button className="Button -size-small" onClick={() => localValue && onSubmit?.(localValue)} disabled={!localValue}>
          <Icon iconName="check" />
          <span className="_label">予約日を決定</span>
        </button>
        <button className="Button -variant-grey -size-small" onClick={() => onCancel?.()}>
          閉じる
        </button>
      </Actions>
    </Base>
  )
}
