export const colors = {
  black: '#222222',
  white: '#ffffff',
  greyD3: '#333333',
  greyD2: '#666666',
  greyD1: '#999999',
  grey: '#C6C6C6',
  greyL1: '#E2E2E2',
  greyL2: '#F0F0F0',
  greyL3: '#F8F8F8',
  blueD3: '#002833',
  blueD2: '#005166',
  blueD1: '#007999',
  blue: '#009DC6',
  blueL1: '#7FCDE2',
  blueL2: '#BFE6F0',
  blueL3: '#E0F3F8',
  redD3: '#330015',
  redD2: '#66002B',
  redD1: '#990040',
  red: '#C60053',
  redL1: '#E27FA9',
  redL2: '#F0BFD4',
  redL3: '#F8E0EA'
} as const

export type ColorName = keyof typeof colors
