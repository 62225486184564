import React, { CSSProperties, ReactElement } from 'react'

// import sprite from '../../images/svg/sprite.svg'

interface Props {
  className?: string;
  iconName?: string;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  fontSize?: CSSProperties['fontSize'];
}

export function Icon (props: Props):ReactElement {
  const styles = {
    width: props.width || '1em',
    height: props.height || '1em',
    fontSize: props.fontSize || 'inherit'
  }

  return (
    <svg className={props.className} style={styles}>
      {/* <title>{props.iconName}</title> */}
      <use xmlnsXlink='http://www.w3.org/1999/xlink' xlinkHref={`/assets/images/svg/sprite.svg#${props.iconName}`} />
    </svg>
  )
}
