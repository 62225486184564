import React, { ReactElement } from 'react'

import styled from 'styled-components'

const Base = styled.div({
  display: 'block',
  color: 'red'
})

interface Props {
  el: HTMLElement;
  children: HTMLCollection;
  name: string;
  value: string;
}

export function Example (props:Props): ReactElement {
  const name = props.el.getAttribute('data-name')

  return (
    <Base>
      {name}
    </Base>
  )
}
