import classNames from 'classnames'
import { DateTime } from 'luxon'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../component/Icon'
import { colors } from '../config/variables'

const Base = styled.div({
  display: 'block'
})

const List = styled.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0
})

const Item = styled.li({
  visibility: 'hidden',
  position: 'absolute',
  opacity: 0,
  transition: 'opacity 0.5s ease 0s',

  '&:not(:first-child)': {
    borderTop: `1px solid ${colors.greyL1}`
  },

  '&.-show': {
    visibility: 'visible',
    position: 'relative',
    opacity: 1
  }
})

const Anchor = styled.a({
  display: 'block',
  padding: 16,
  color: colors.blue,
  textDecoration: 'none',
  lineHeight: 1.5,
  '&:hover': {
    color: colors.blueD1,
    backgroundColor: colors.blueL3
  }
})

const Title = styled.span({
  fontSize: 18
})

const Time = styled.time({
  display: 'block',
  marginTop: 8,
  fontSize: 14,
  color: colors.greyD1
})

const Action = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 24,
  padding: 16,
  backgroundColor: colors.greyL2,
  borderRadius: 16
})

const LoadMoreButton = styled.button({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
  justifyCOntent: 'center',
  border: `2px solid ${colors.grey}`,
  backgroundColor: colors.greyL3,
  height: 40,
  fontSize: 16,
  borderRadius: 40,
  padding: '0 16px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: colors.white
  }

})

const DummyNewsTitles = [
  'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら',
  'うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波',
  'またそのなかでいっしょになったたくさんのひとたち、ファゼーロとロザーロ、羊飼のミーロや、顔の赤いこどもたち、地主のテーモ、山猫博士のボーガント・デストゥパーゴなど',
  'いまこの暗い巨きな石の建物のなかで考えていると、みんなむかし風のなつかしい青い幻燈のように思われます'
]

const DummyNewsItems = Array.from({ length: 100 }).map((_, i) => {
  return {
    title: DummyNewsTitles[i % DummyNewsTitles.length],
    date: DateTime.fromJSDate(new Date(2022, 0, i * -1)).toFormat('yyyy-MM-dd'),
    url: `/news/${i}`
  }
})

interface NewsItem {
  title: string
  date: string
  url: string
}

interface Props {
  children: HTMLCollection
}

export function NewsIndex ({ children }:Props):ReactElement {
  // TODO: WPと結合の際にはfalseにする
  const isDummy = false
  const [items, setItems] = useState<NewsItem[]>([])
  const [count, setCount] = useState<number>(10)

  useEffect(() => {
    if (isDummy) {
      setItems(DummyNewsItems)
      return
    }
    try {
      const json = JSON.parse(
        Array.prototype.slice.call(children)
          .find(child => child.tagName === 'SCRIPT')
          .innerHTML
      )
      setItems(json)
    } catch (e) {}
  }, [])

  return (
    <Base>
      <List>
        {items.map((item, i) => (
          <Item key={`item-${item.url}`} className={classNames(i < count && '-show')}>
            <Anchor href={item.url}>
              <Title>{item.title}</Title>
              <Time>{DateTime.fromFormat(item.date, 'yyyy-MM-dd').toFormat('yyyy年MM月dd日')}</Time>
            </Anchor>
          </Item>
        ))}
      </List>
      {items.length > count && (
        <Action>
          <LoadMoreButton onClick={() => setCount(pre => pre + 10)}>
            <Icon iconName="arrow-down" />
            もっと見る
          </LoadMoreButton>
        </Action>
      )}
    </Base>
  )
}
