import { useState } from 'react'
import { ShopName, shopNames } from './reservation'
import validator from 'validator'
import axios from 'axios'

export const subjects = {
  a: '取り扱い補聴器に関するお問い合わせ',
  b: 'ご来店・ご予約に関するお問い合わせ',
  c: '補聴器の調整・メンテナンスに関するお問い合わせ',
  d: 'その他のお問い合わせ'
} as const

interface State {
  token: string | null
  shop: ShopName | null
  subject: keyof typeof subjects | null
  name: string | null
  email: string | null
  phone: string | null
  message: string | null
}

const initialState: State = {
  token: null,
  shop: null,
  subject: null,
  name: null,
  email: null,
  phone: null,
  message: null
}

type Errors = {
  // eslint-disable-next-line no-unused-vars
  [key in keyof State]?: string
}

export function useContact () {
  const [state, setState] = useState<State>(initialState)
  const [errors, setErrors] = useState<Errors>({})

  const update = (key: keyof State, value: any) => {
    setState(pre => {
      validate(key, value)
      return ({ ...pre, [key]: value })
    })
  }

  const validate = (key?: keyof State, _value?: any):boolean => {
    if (!key) {
      return Object.keys(state).map(key => validate(key as keyof State)).every(v => v)
    }
    const e:Errors = {}
    e[key] = undefined
    const value = _value === undefined ? state[key] : _value
    switch (key) {
      case 'shop':
        if (!Object.keys(shopNames).includes(value as string)) {
          e.shop = '店舗を選択してください'
        }
        break
      case 'subject':
        if (!Object.keys(subjects).includes(value as string)) {
          e.subject = '件名を選択してください'
        }
        break
      case 'name':
        if (!value) {
          e.name = 'お名前を入力してください'
        } else if (Array.from(value).length > 100) {
          e.name = 'お名前は100文字以内で入力してください'
        }
        break
      case 'email':
        if (!value) {
          e.email = 'メールアドレスを入力してください'
        } else if (!validator.isEmail(value)) {
          e.email = 'メールアドレスの形式が正しくありません'
        } else if (Array.from(value).length > 254) {
          e.email = 'メールアドレスは254文字以内で入力してください'
        }
        break
      case 'phone':
        if (value) {
          if (!/^[0-9\-()]{6,20}/.test(value)) {
            e.phone = '電話番号は半角数字とハイフン、括弧のみで入力してください'
          }
        }
        break
      case 'message':
        if (!value) {
          e.message = 'お問い合わせ内容を入力してください'
        } else if (Array.from(value).length > 1000) {
          e.message = 'お問い合わせ内容は1000文字以内で入力してください'
        }
        break
      default: break
    }
    setErrors(pre => ({ ...pre, ...e }))
    return !e[key]
  }

  const fetchToken = () => {
    axios.get('/api/index.php/token').then(res => {
      setState(pre => ({ ...pre, token: res.data.token }))
    })
  }

  const submit = ():Promise<any> => {
    const data = new FormData()
    Object.keys(state).forEach(key => {
      let value = state[key as keyof State]
      if (key === 'subject') {
        value = subjects[value as keyof typeof subjects]
      }
      if (value) {
        data.append(key, value as string)
      }
    })

    return axios.post('/api/index.php/contact', data)
  }

  return [
    state,
    update,
    errors,
    validate,
    fetchToken,
    submit
  ] as [
    State,
    typeof update,
    Errors,
    typeof validate,
    typeof fetchToken,
    typeof submit
  ]
}
