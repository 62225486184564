import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FieldError } from '../component/FieldError'
import { FormTable, FormTableCell, FormTableHead } from '../component/FormTable'
import { MessageBox } from '../component/MessageBox'
import { RadioButton, RadioButtonGroup } from '../component/RadioButton'
import { TextArea } from '../component/TextArea'
import { TextInput } from '../component/TextInput'
import { colors } from '../config/variables'
import { useAnalytics } from '../hooks/analytics'
import { useContact, subjects } from '../hooks/contact'
import { ShopName, shopNames } from '../hooks/reservation'

const Base = styled.div({
  display: 'block'
})

const Note = styled.p({
  margin: 0,
  padding: '24px 0',
  fontSize: 16,
  color: colors.black,
  lineHeight: 1.75
})

export function ContactForm (): ReactElement {
  const [progress, setProgress] = useState<number>(0)
  const [state, update, errors, validate, fetchToken, submit] = useContact()
  const [loading, setLoading] = useState<boolean>(false)
  const { trackEvent } = useAnalytics()

  const scrollTop = () => {
    document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleConfirm = () => {
    if (validate()) {
      setProgress(1)
    } else {
      scrollTop()
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    submit().then(() => {
      setProgress(2)
      setLoading(false)
      trackEvent('submit_contact')
    })
      .catch(() => {
        setProgress(3)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchToken()
  }, [])

  useEffect(() => {
    scrollTop()
  }, [progress])

  return (
    <Base>
      {progress === 0 && (
        <>
          <Note>
            下のフォームよりお問い合わせください。後ほど担当者よりご連絡いたします。
            <br />
            （返信にお時間をいただく場合がございます）
          </Note>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="お問い合わせ店舗" />
              <FormTableCell>
                <RadioButtonGroup>
                  <RadioButton
                    label="柏補聴器センター"
                    onChange={() => update('shop', 'kashiwa')}
                    onBlur={() => validate('shop')}
                    checked={state.shop === 'kashiwa'}
                  />
                  <RadioButton
                    label="松戸補聴器センター"
                    onChange={() => update('shop', 'matsudo')}
                    onBlur={() => validate('shop')}
                    checked={state.shop === 'matsudo'}
                  />
                </RadioButtonGroup>
                <FieldError message={errors.shop} />
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="ご用件" />
              <FormTableCell>
                <RadioButtonGroup direction='column'>
                  {Object.keys(subjects).map(key => (
                    <RadioButton
                      key={`subject-${key}`}
                      label={subjects[key as keyof typeof subjects]}
                      onChange={() => update('subject', key)}
                      onBlur={() => validate('subject')}
                      checked={state.subject === key}
                    />
                  ))}
                </RadioButtonGroup>
                <FieldError message={errors.subject} />
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="お名前" />
              <FormTableCell>
                <TextInput
                  size="double"
                  placeholder="柏 太郎"
                  value={state.name || ''}
                  onChange={value => update('name', value)}
                  onBlur={() => validate('name')}
                  isInvalid={!!errors.name}
                />
                <FieldError message={errors.name} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="メールアドレス" />
              <FormTableCell>
                <TextInput
                  size="double"
                  placeholder="taro@example.com"
                  value={state.email || ''}
                  onChange={value => update('email', value)}
                  onBlur={() => validate('email')}
                  isInvalid={!!errors.email}
                />
                <FieldError message={errors.email} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="電話番号" optional />
              <FormTableCell>
                <TextInput
                  size="double"
                  placeholder="04-7167-4133"
                  value={state.phone || ''}
                  onChange={value => update('phone', value)}
                  onBlur={() => validate('phone')}
                  isInvalid={!!errors.phone}
                />
                <FieldError message={errors.phone} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="お問い合わせ内容" />
              <FormTableCell>
                <TextArea
                  value={state.message || ''}
                  onChange={value => update('message', value)}
                  onBlur={() => validate('message')}
                  isInvalid={!!errors.message}
                />
                <FieldError message={errors.message} />
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="" />
              <FormTableCell>
                <button className="Button" onClick={handleConfirm}>内容を確認</button>
              </FormTableCell>
            </tr>
          </FormTable>
        </>
      )}
      {progress === 1 && (
        <>
          <Note>
            入力内容をご確認のうえ、送信ボタンを押してください。
          </Note>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="お問い合わせ店舗" />
              <FormTableCell confirm>
                {shopNames[state.shop as ShopName]}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="ご用件" />
              <FormTableCell confirm>
                {subjects[state.subject as keyof typeof subjects]}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="お名前" />
              <FormTableCell confirm>
                {state.name}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="メールアドレス" />
              <FormTableCell confirm>
                {state.email}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="電話番号" optional />
              <FormTableCell confirm>
                {state.phone || '-'}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="お問い合わせ内容" />
              <FormTableCell preline confirm>
                {state.message}
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="" />
              <FormTableCell>
                <div className="ButtonGroup">
                  <button className="Button -size-small" onClick={handleSubmit} disabled={loading}>送信</button>
                  <button className="Button -size-small -variant-grey" onClick={() => setProgress(0)} disabled={loading}>編集する</button>
                </div>
              </FormTableCell>
            </tr>
          </FormTable>
        </>
      )}
      {progress === 2 && (
        <MessageBox iconName="check">
          <div>
            お問い合わせ内容を送信しました。
            <br />
            お店から確認のご連絡を差し上げる場合がございます。ご了承ください。
          </div>
          <a href="/" className="Button">トップページへ戻る</a>
        </MessageBox>
      )}
      {progress === 3 && (
        <MessageBox iconName="error" iconColor='red' backgroundColor='redL3'>
          <div>
            送信中にエラーが発生しました。
            <br />
            時間を置いて再度お試しいただくか、電話にてお問い合わせください。
          </div>
          <button className="Button -size-small" onClick={() => setProgress(0)}>再度送信する</button>
        </MessageBox>
      )}
    </Base>
  )
}
