import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Base = styled.div({
  fontSize: 14,
  color: colors.red,
  '&:not(:first-child)': {
    marginTop: 8
  }
})

interface Props {
  message?: string
}

export function FieldError ({
  message
}:Props):ReactElement {
  return (
    <>
      {message && (
        <Base>{message}</Base>
      )}
    </>
  )
}
