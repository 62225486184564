import React from 'react'
import ReactDOM from 'react-dom'
import { Icon } from './js/component/Icon'
import views from './js/view'
import { Slots } from './js/interfaces'

// render icons

document.querySelectorAll('[data-icon]')
  .forEach(el => {
    const iconName = el.getAttribute('data-icon') || undefined
    ReactDOM.render(React.createElement(Icon, { iconName }), el)
  })

// render view

type ViewName = keyof typeof views

function getSlots (html: string):Slots {
  const root = document.createElement('div')
  root.innerHTML = html
  const slots = root.querySelectorAll('[data-slot]')
  const result: Slots = {}
  if (slots) {
    Array.from(slots).forEach(it => {
      const name = it.getAttribute('data-slot') || ''
      if (name) {
        result[name] = it as HTMLElement
      }
    })
  }
  return result
}

document.querySelectorAll('[data-view]')
  .forEach(el => {
    const viewName = el.getAttribute('data-view') as string

    if (viewName in views) {
      const tmp = document.createElement('div')
      const slots = getSlots(el.innerHTML)
      tmp.innerHTML = el.innerHTML
      const props: any = {
        el,
        children: tmp.children,
        root: tmp,
        slots
      }

      ReactDOM.render(React.createElement(views[viewName as ViewName], props), el)
    }
  })

// link

document.querySelector('body')?.addEventListener('click', event => {
  const target = event.target as HTMLElement
  const anchor = target.nodeName === 'A' ? target : target.closest('a')
  if (anchor) {
    const { hostname } = anchor as HTMLAnchorElement
    if (hostname !== window.location.hostname) {
      anchor.setAttribute('target', '_blank')
    }
  }
})

// track events

document.querySelector('body')?.addEventListener('click', event => {
  const target = event.target as HTMLElement
  const name = target.getAttribute('data-track') || ''
  // @ts-ignore
  if (name && window.gtag) {
    // @ts-ignore
    window.gtag('event', 'click', { name })
  }
})

// responsive

// const viewport = document.querySelector('meta[name="viewport"]')

// const handleResize = () => {
//   const innerWidth = window.innerWidth
//   const breakpoints = [640, 1024]

//   breakpoints.forEach((width) => {
//     if (innerWidth < width) {
//       viewport?.setAttribute('content', `width=${width}, user-scalable=no`)
//     }
//   })
// }

// window.addEventListener('resize', handleResize)
// document.addEventListener('DOMContentLoaded', handleResize)
