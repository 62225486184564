import classNames from 'classnames'
import React, { ChangeEvent, ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Base = styled.label({
  display: 'inline-flex'
})

const NativeInput = styled.input({
  position: 'absolute',
  opacity: 0
})

const Content = styled.div({
  display: 'flex',
  padding: '0 16px',
  height: 48,
  alignItems: 'center',
  borderRadius: 48,
  'input:checked + &': {
    backgroundColor: colors.blueL3
  }
})

const RadioIcon = styled.div({
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  borderRadius: 24,
  border: `1px solid ${colors.greyD1}`,
  backgroundColor: colors.white,

  '&::before': {
    content: '""',
    backgroundColor: colors.greyL2,
    width: 12,
    height: 12,
    borderRadius: 12
  },

  'input:checked + div > &': {
    '&::before': {
      backgroundColor: colors.blue

    }
  }
})

const Label = styled.div({
  fontSize: 16,
  color: colors.black,
  marginLeft: 8
})

interface Props {
  label: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void
}

export function RadioButton ({ label, checked, onChange, onBlur }:Props):ReactElement {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked)
  }

  return (
    <Base>
      <NativeInput
        type="radio"
        checked={checked}
        onChange={handleChange}
        onBlur={onBlur}
      />
      <Content>
        <RadioIcon />
        <Label>
          {label}
        </Label>
      </Content>
    </Base>
  )
}

const GroupBase = styled.div({
  display: 'flex',
  gap: 16,
  '&.-direction-column': {
    flexDirection: 'column'
  },
  '@media screen and (max-width: 768px)': {
    flexDirection: 'column'
  }
})

interface GroupProps {
  children: ReactNode
  direction?: 'row' | 'column'
}

export function RadioButtonGroup ({
  children,
  direction = 'row'
}:GroupProps):ReactElement {
  return (
    <GroupBase className={classNames(direction && `-direction-${direction}`)}>
      {children}
    </GroupBase>
  )
}
