import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../component/Icon'
import { colors } from '../config/variables'
import { debounce } from 'lodash'
import classNames from 'classnames'

const Base = styled.div({
  display: 'block',
  position: 'fixed',
  right: 0,
  bottom: 0,
  width: '100%'
})

const Drawer = styled.div({
  position: 'absolute',
  right: 0,
  bottom: 0,
  padding: '24px 40px',
  backgroundColor: colors.blue,
  color: colors.white,
  borderRadius: '24px 0 0 0',
  border: '2px solid white',
  borderWidth: '2px 0 0 2px',
  transform: 'translateX(100%)',
  transition: 'transform 0.3s ease 0s',
  '&.-open': {
    transform: 'translateX(0)'
  },

  '@media screen and (max-width: 768px)': {
    borderRadius: 0,
    borderWidth: 0,
    padding: 16,
    width: '100%',
    boxSizing: 'border-box'
  }
})

const CloseButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  position: 'absolute',
  left: 8,
  top: 8,
  fontSize: 16,
  backgroundColor: colors.white,
  border: 'none',
  borderRadius: 32,
  color: colors.blue,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.blueL3
  },

  '@media screen and (max-width: 768px)': {
    border: `2px solid ${colors.blue}`,
    right: 8,
    left: 'auto',
    top: -16
  }

})

const Heading = styled.h3({
  display: 'flex',
  margin: 0,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
  lineHeight: 1.5,
  fontWeight: 400,
  color: colors.blueL3,
  svg: {
    width: 32,
    height: 32,
    marginRight: 8
  },

  '@media screen and (max-width: 768px)': {
    fontSize: 16
  }
})

const Phones = styled.div({
  display: 'flex',
  gap: 24,
  padding: '24px 0',

  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    padding: '16px 0'
  }
})

const Phone = styled.a({
  display: 'block',
  color: colors.white,
  textDecoration: 'none',
  '&:hover': {
    color: colors.white
  }
})

const PhoneLabel = styled.div({
  fontSize: 13,
  lineHeight: 1
})

const PhoneNumber = styled.div({
  fontSize: 40
})

const Divider = styled.div({
  border: 'none',
  borderTop: `1px solid ${colors.blueL1}`
})

const Controls = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0 0',
  gap: 24,

  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
    gap: 16,
    padding: '16px 0 0'
  }
})

const Button = styled.a({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  color: colors.white,
  textDecoration: 'none',
  border: `2px solid ${colors.white}`,
  borderRadius: 8,
  padding: '0 24px',
  height: 56,
  fontSize: 24,
  '&:hover': {
    backgroundColor: colors.blueD1,
    color: colors.white
  },

  '@media screen and (max-width: 768px)': {
    height: 40,
    padding: '0 16px',
    fontSize: 20
  }
})

const ButtonLabel = styled.span({
  fontSize: 20
})

export function ContactDrawer ():ReactElement {
  const [open, setOpen] = useState<boolean>(false)
  const [closed, setClosed] = useState<boolean>(false)
  const timing = 180000
  // const timing = 1000

  const delay = debounce(() => {
    setOpen(true)
  }, timing)

  useEffect(() => {
    const events = 'load,scroll,click,mousemove,keydown'.split(',')
    events.forEach(event => {
      window.addEventListener(event, delay)
    })
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, delay)
      })
    }
  }, [])

  return (
    <Base>
      <Drawer className={classNames(open && !closed && '-open')}>
        <CloseButton onClick={() => setClosed(true)}>
          <Icon iconName="close" />
        </CloseButton>
        <Heading>
          <Icon iconName='phone' />
          お電話にてご予約・ご相談承ります。
        </Heading>
        <Phones>
          <Phone href="tel:04771674133">
            <PhoneLabel>
              柏補聴器センター
            </PhoneLabel>
            <PhoneNumber className="font-en">04-7167-4133</PhoneNumber>
          </Phone>
          <Phone href="tel:0473944137">
            <PhoneLabel>
              松戸補聴器センター
            </PhoneLabel>
            <PhoneNumber className="font-en">047-394-4137</PhoneNumber>
          </Phone>
        </Phones>
        <Divider />
        <Controls>
          <Button href="/contact">
            <Icon iconName='mail' />
            <ButtonLabel>メールでお問い合わせ</ButtonLabel>
          </Button>
          <Button href="/reserve">
            <Icon iconName='calendar' />
            <ButtonLabel>WEB予約</ButtonLabel>
          </Button>
        </Controls>
      </Drawer>
    </Base>
  )
}
