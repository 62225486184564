import classNames from 'classnames'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../component/Icon'
import { IconButton } from '../component/IconButton'
import { colors } from '../config/variables'

const Base = styled.div({
  display: 'block',
  width: '100%',
  height: 64
})

const Inner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 64,
  padding: '0 0 0 1rem',
  position: 'fixed',
  boxSizing: 'border-box',
  backgroundColor: colors.white,
  boxShadow: '0 0 16px rgba(0, 0, 0, 0.2)',
  zIndex: 2
})

const Logos = styled.h1({
  margin: 0,
  '> a': {
    display: 'flex',
    gap: 16
  }
})

const Nav = styled.nav({
  display: 'flex'
})

const MenuBase = styled.div({
  zIndex: 1,
  position: 'fixed',
  top: 64,
  left: 0,
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  pointerEvents: 'none',
  opacity: 0,
  transition: 'all .3s ease 0s',

  '&.-show': {
    pointerEvents: 'auto',
    opacity: 1
  }
})

const Menu = styled.ul({
  backgroundColor: colors.greyL3,
  listStyleType: 'none',
  margin: 0,
  padding: 8,
  transform: 'translateY(-120%)',
  transition: 'all .3s ease 0s',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,

  '&.-show': {
    transform: 'translateY(0)'
  }
})

const MenuItem = styled.li({
  fontSize: 16,
  '> a': {
    display: 'block',
    padding: 16,
    textDecoration: 'none',
    '&.-active': {
      backgroundColor: colors.blueL3
    }
  },
  '&:not(:first-child)': {
    '> a': {
      borderTop: `1px solid ${colors.greyL1}`
    }
  }
})

const MenuItemMultiple = styled.li({
  display: 'flex',
  justifyContent: 'center',
  padding: 24,
  gap: 24,
  '&:not(:first-child)': {
    borderTop: `1px solid ${colors.greyL1}`
  }
})

const CloseButtonBase = styled.div({
  display: 'flex',
  padding: 16
})

const CloseButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  padding: 8,
  width: '100%',
  boxSizing: 'border-box',
  border: 'none',
  backgroundColor: colors.greyL1,
  color: colors.black,
  fontSize: 14,
  borderRadius: 100
})

const PhoneLink = styled.a({
  display: 'flex !important',
  alignItems: 'flex-end',
  justifyContent: 'space-between',

  '> div:first-child': {
    display: 'flex',
    flexDirection: 'column',
    '> small': {
      fontSize: 12
    },
    '> strong': {
      fontSize: 32,
      fontWeight: 400
    }
  },

  svg: {
    transform: 'scale(-1, 1)',
    opacity: 0.5
  }
})

const menuItems = [
  {
    name: 'about',
    path: '/about',
    label: '当センターについて'
  },
  {
    name: 'shop',
    path: '/shop',
    label: '店舗情報'
  },
  // {
  //   path: '/product',
  //   label: '補聴器'
  // },
  {
    name: 'purchase',
    path: '/purchase',
    label: '購入の流れ'
  },
  {
    name: 'blog',
    path: 'http://hearingstudio.livedoor.blog',
    label: 'ブログ',
    target: '_blank'
  },
  {
    name: 'contact',
    path: '/contact',
    label: 'お問い合わせ'
  }
]

type MenuVisible = 'menu' | 'phone' | null

export function MobileHeader ():ReactElement {
  const [menu, setMenu] = useState<MenuVisible>(null)
  const [pageName, setPageName] = useState<string>('')

  useEffect(() => {
    const name = document.querySelector('body')?.getAttribute('data-page')
    setPageName(name || '')
  }, [])

  const toggleMenu = (name: MenuVisible) => {
    setMenu(menu === name ? null : name)
  }

  return (
    <Base>
      <Inner>
        <Logos>
          <a href="/">
            <Icon iconName='logo-khac' width={80} height={32} />
            <Icon iconName='logo-mhac' width={86} height={32} />
          </a>
        </Logos>
        <Nav>
          <IconButton iconName='phone' onClick={() => toggleMenu('phone')} buttonSize={64} iconSize={32} />
          <IconButton iconName='menu' onClick={() => toggleMenu('menu')} buttonSize={64} iconSize={32} />
        </Nav>
      </Inner>
      <MenuBase
        className={classNames(menu !== null && '-show')}
        onClick={() => toggleMenu(null)}
      >
        <Menu
          className={classNames(menu === 'menu' && '-show')}
          onClick={event => event.stopPropagation()}
        >
          {menuItems.map(it => (
            <MenuItem key={`menu-item-${it.path}`}>
              <a
                href={it.path}
                className={classNames(it.name === pageName && '-active')}
                target={it.target}
              >{it.label}</a>
            </MenuItem>
          ))}
          <MenuItemMultiple>
            <a href="https://www.facebook.com/hearinghc1986" target="_blank" rel="noreferrer">
              <Icon iconName='facebook' fontSize={32} />
            </a>
            <a href="https://www.instagram.com/hearingstudiohc/" target="_blank" rel="noreferrer">
              <Icon iconName='instagram' fontSize={32} />
            </a>
          </MenuItemMultiple>
          <CloseButtonBase>
            <CloseButton onClick={() => setMenu(null)}>閉じる</CloseButton>
          </CloseButtonBase>
        </Menu>
        <Menu
          className={classNames(menu === 'phone' && '-show')}
          onClick={event => event.stopPropagation()}
        >
          <MenuItem>
            <PhoneLink href="tel:0471674133">
              <div>
                <small>柏補聴器センター</small>
                <strong>04-7167-4133</strong>
              </div>
              <Icon iconName='phone' fontSize={32} />
            </PhoneLink>
          </MenuItem>
          <MenuItem>
            <PhoneLink href="tel:0473944137">
              <div>
                <small>松戸補聴器センター</small>
                <strong>047-394-4137</strong>
              </div>
              <Icon iconName='phone' fontSize={32} />
            </PhoneLink>
          </MenuItem>
          <CloseButtonBase>
            <CloseButton onClick={() => setMenu(null)}>閉じる</CloseButton>
          </CloseButtonBase>
        </Menu>
      </MenuBase>
    </Base>
  )
}
