import classNames from 'classnames'
import React, { createRef, ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'
import { Slots } from '../interfaces'
import { responsive } from '../utils/responsive'

const Base = styled.div({
  display: 'block',
  backgroundColor: colors.greyL2,
  padding: 24,
  height: '40vh',
  overflow: 'hidden',
  position: 'relative',
  transition: 'height 0.5s ease',
  ...responsive(768, {
    padding: 0,
    backgroundColor: colors.white
  })
})

const Inner = styled.div({
  backgroundColor: colors.white,
  borderRadius: 4,
  padding: '48px 24px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  ...responsive(768, {
    padding: 16,
    boxShadow: 'none'
  })
})

const Title = styled.h3({
  fontSize: 32,
  fontWeight: 'normal',
  margin: 0,
  textAlign: 'center',
  color: colors.blue,
  ...responsive(768, {
    fontSize: 24
  })
})

const Body = styled.div({
  display: 'flex',
  margin: '48px 0 0',
  gap: 48,
  '&.-no-image': {
    justifyContent: 'center'
  },
  ...responsive(768, {
    flexDirection: 'column',
    gap: 24
  })
})

const TextContent = styled.div({
  flex: '1 1 auto',
  fontSize: 16,
  lineHeight: 1.75,
  '.-no-image > &': {
    flex: '0 0 80%'
  }
})

const ImageContent = styled.div({
  flex: '0 0 30vw',
  '& > ul': {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    margin: 0,
    padding: 0,
    '& > li': {
      margin: 0,
      '& > img': {
        width: '100%',
        height: 'auto'
      }
    }
  }
})

const ExpandButton = styled.div({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: 128,
  border: 'none',
  background: 'linear-gradient(to bottom, rgba(240,240,240,0) 0%, rgba(240,240,240,1) 50%, rgba(240,240,240,1) 100%)',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'stretch',
  padding: 8,
  boxSizing: 'border-box',
  '& > button': {
    border: `3px solid ${colors.blueL2}`,
    color: colors.blue,
    flex: '1 1 auto',
    borderRadius: 128,
    padding: 8,
    backgroundColor: colors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blueL3
    }
  }
})

interface Props {
  children?: HTMLCollection
  root?: HTMLElement
  slots?: Slots
}

export function HomeNoticeBlock ({ slots }:Props):ReactElement {
  const baseRef = createRef<HTMLDivElement>()
  const innerRef = createRef<HTMLDivElement>()
  const contentRef = createRef<HTMLDivElement>()
  const [images, setImages] = useState<string[]>([])
  const [overflow, setOverflow] = useState<boolean>(false)
  const [height, setHeight] = useState<number|undefined>(undefined)

  const title = slots?.title?.innerText || ''
  const body = slots?.body || document.createElement('div')

  const handleExpand = () => {
    if (innerRef.current) {
      setHeight(innerRef.current.offsetHeight)
      setOverflow(false)
    }
  }

  useEffect(() => {
    // parse body
    const html = body.innerHTML
      .replace(/<br.*?>/g, '\n')
      .replace(/<a\s.*?href="([\S]+?)".*?>/g, '[href=$1]')
      .replace(/<\/a>/g, '[/href]')
      .replace(/<[a-z/]+.*?>/g, '')
      .replace(/\[href=([\S]+?)\]/g, '<a href="$1">')
      .replace(/\[\/href\]/g, '</a>')
      .replace(/\n/g, '<br>')

    if (contentRef.current) {
      contentRef.current.innerHTML = html
    }

    // parse image
    setImages(Array.from(body.querySelectorAll('img')).map(img => img.src))

    // calc overflow
    if (baseRef.current && innerRef.current) {
      setOverflow(baseRef.current?.offsetHeight < baseRef.current?.scrollHeight)
    }
  }, [])

  return (
    <Base ref={baseRef} style={{ height }}>
      <Inner ref={innerRef}>
        <Title>{title}</Title>
        <Body className={classNames(!images.length && '-no-image')}>
          <TextContent ref={contentRef} />
          {images.length > 0 && (
            <ImageContent>
              <ul>
                {images.slice(0, 3).map(image => {
                  return <li key={image}><img src={image} /></li>
                })}
              </ul>
            </ImageContent>
          )}
        </Body>
      </Inner>
      {overflow && (
        <ExpandButton>
          <button onClick={handleExpand}>もっと見る</button>
        </ExpandButton>
      )}
    </Base>
  )
}
