import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Table = styled.table({
  width: '100%',
  tableLayout: 'fixed',
  '@media screen and (max-width: 768px)': {
    tableLayout: 'auto',
    margin: '16px 0'
  }
})

const Column1 = styled.col({
  width: 200,
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
})

const Column2 = styled.col({
  width: '100%'
})

interface Props {
  children: ReactNode
}

export function FormTable ({ children }: Props):ReactElement {
  return (
    <Table>
      <colgroup>
        <Column1 />
        <Column2 />
      </colgroup>
      <tbody>
        {children}
      </tbody>
    </Table>
  )
}

const TableHead = styled.th({
  color: colors.black,
  padding: '16px 0',
  verticalAlign: 'top',

  '&.-empty': {
    padding: 0
  },

  '@media screen and (max-width: 768px)': {
    display: 'block',
    padding: 0
  }
})

const TableHeadInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px 0 0',
  gap: 16,
  height: 48
})

const Label = styled.span({
  fontSize: 16,
  fontWeight: 'normal'
})

const OptionalLabel = styled.span({
  padding: '4px 8px',
  border: `1px solid ${colors.greyD1}`,
  borderRadius: 4,
  fontSize: 13,
  fontWeight: 'normal'
})

interface HeadProps {
  label: string;
  optional?: boolean;
}

export function FormTableHead ({ label, optional }: HeadProps):ReactElement {
  return (
    <TableHead className={classNames(!label && '-empty')}>
      {label && (
        <TableHeadInner>
          <Label>{label}</Label>
          {optional && <OptionalLabel>任意</OptionalLabel>}
        </TableHeadInner>
      )}
    </TableHead>
  )
}

const Cell = styled.td({
  padding: '16px 0',
  '&.-preline': {
    whiteSpace: 'pre-line',
    lineHeight: 1.75
  },
  '@media screen and (max-width: 768px)': {
    display: 'block',
    padding: '8px 0',
    '&.-confirm': {
      backgroundColor: colors.greyL2,
      padding: 8,
      borderRadius: 4
    }
  }
})

interface CellProps {
  children: ReactNode
  preline?: boolean
  confirm?: boolean
}

export function FormTableCell ({ children, preline, confirm }: CellProps):ReactElement {
  return <Cell className={classNames(preline && '-preline', confirm && '-confirm')}>{children}</Cell>
}
