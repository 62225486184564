/* eslint-disable no-undef */
import { useState } from 'react'

const apiKey = 'AIzaSyCO3-F2ZV_tjsbAcKbcpe2FcsL4ObW2Wyk'
const discoveryDocs = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest']
const scopes = 'https://www.googleapis.com/auth/calendar.readonly'

export function useCalendar () {
  const [holidays, setHolidays] = useState<string[]>([])

  const init = () => {
    const d = new Date()
    const timeMin = d.toISOString()
    const timeMax = new Date(d.getFullYear(), d.getMonth() + 4, 0).toISOString()

    const { gapi } = window as any

    gapi.load('client', () => {
      gapi.client.init({
        apiKey,
        discoveryDocs,
        scopes
      }).then(() => {
        Promise.all(
          [
            gapi.client.calendar.events.list({
              calendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
              timeMin,
              timeMax,
              orderBy: 'startTime',
              singleEvents: true
            }),
            gapi.client.calendar.events.list({
              calendarId: 'heh9bv14jif06ubh3t2gdon8pg@group.calendar.google.com',
              maxResults: 90,
              timeMin,
              timeMax,
              orderBy: 'startTime',
              singleEvents: true
            })
          ]
        )
          .then((responses) => {
            const list: string[] = []
            responses.forEach(res => {
              res.result.items.forEach((it:any) => {
                const date = it.start.date
                if (!list.includes(date)) {
                  list.push(date)
                }
              })
            })
            setHolidays(list)
          })
      })
    })
  }

  if (!document.getElementById('gapi-script')) {
    const element = document.createElement('script')
    element.setAttribute('id', 'gapi-script')
    element.addEventListener('load', init)
    element.src = 'https://apis.google.com/js/api.js'
    document.querySelector('head')?.appendChild(element)
  }

  return {
    holidays
  }
}
