export function useAnalytics () {
  const trackEvent = (eventName: string, eventProperties?: object) => {
    // console.log({ eventName, eventProperties })
    if (/localhost/.test(location.host)) {
      console.log({ eventName, eventProperties })
      return
    }
    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('event', eventName, eventProperties)
    }
  }
  return { trackEvent }
}
