
import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Overlay = styled.div({
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100vh',
  left: 0,
  top: 0,
  zIndex: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  visibility: 'hidden',
  opacity: 0,
  transition: 'all 0.3s ease 0s',
  '&.-show': {
    visibility: 'visible',
    opacity: 1
  }
})

const Dialog = styled.div({
  display: 'block',
  width: 640,
  height: 'auto',
  minHeight: 320,
  backgroundColor: colors.white,
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
  padding: 16,
  borderRadius: 4
})

interface Props {
  children: ReactNode
  show?: boolean
  onClose?: () => void
}

export function Modal ({ children, show, onClose }:Props):ReactElement {
  return (
    <Overlay className={classNames(show && '-show')} onClick={() => onClose?.()}>
      <Dialog onClick={event => event.stopPropagation()}>
        {children}
      </Dialog>
    </Overlay>
  )
}
