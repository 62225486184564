import classNames from 'classnames'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'

const Input = styled.input({
  fontSize: 16,
  fontFamily: 'inherit',
  color: colors.black,
  border: `1px solid ${colors.greyD1}`,
  backgroundColor: colors.greyL3,
  borderRadius: 4,
  height: 48,
  padding: '0 16px',
  outline: 'none',
  boxSizing: 'border-box',

  '&:focus': {
    borderColor: colors.blue
  },

  '&::placeholder': {
    color: colors.grey
  },

  '&.-invalid': {
    borderColor: colors.red,
    color: colors.red,
    backgroundColor: colors.redL3
  },

  '&.-size-single': {
    width: 320
  },

  '&.-size-double': {
    width: 640
  },

  '&.-size-full': {
    width: '100%'
  },

  '@media screen and (max-width: 768px)': {
    '&, &.-size-single, &.-size-double': {
      width: '100%'
    }
  }
})

interface Props {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  size?: 'single' | 'double' | 'full'
}

export function TextInput ({ value, placeholder, onChange, onBlur, isInvalid, size = 'single' }:Props): ReactElement {
  return (
    <Input
      type="text" {...{ value, placeholder, onBlur }}
      onChange={e => onChange?.(e.target.value)}
      className={classNames(isInvalid && '-invalid', size && `-size-${size}`)}
    />
  )
}
