import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { ColorName, colors } from '../config/variables'
import { Icon } from './Icon'

const Button = styled.button({
  border: 'none',
  display: 'inline-flex',
  padding: 0,
  backgroundColor: 'transparent',
  width: 32,
  height: 32,
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover, &:active': {
    backgroundColor: colors.blueL3
  }
})

interface Props {
  iconName: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonSize?: number;
  iconSize?: number;
  color?: ColorName
}

export function IconButton ({
  iconName,
  onClick = () => undefined,
  buttonSize = 32,
  iconSize = 24,
  color = 'blue'
}:Props):ReactElement {
  const styles = {
    color: colors[color],
    width: buttonSize,
    height: buttonSize
  }
  return (
    <Button onClick={onClick} style={styles}>
      <Icon iconName={iconName} fontSize={iconSize} />
    </Button>
  )
}
