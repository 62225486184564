import classNames from 'classnames'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from '../config/variables'
import { Icon } from './Icon'

const Base = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 16,
  fontFamily: 'inherit',
  color: colors.black,
  border: `1px solid ${colors.greyD1}`,
  backgroundColor: colors.greyL3,
  borderRadius: 4,
  height: 48,
  padding: '0 16px',
  boxSizing: 'border-box',
  cursor: 'pointer',

  '&:hover': {
    borderColor: colors.blue,
    backgroundColor: colors.blueL3
  },

  '&.-size-single': {
    width: 320
  },

  '&.-size-double': {
    width: 640
  },

  '&.-size-full': {
    width: '100%'
  },

  '&.-invalid': {
    borderColor: colors.red,
    backgroundColor: colors.redL3
  }
})

const Value = styled.span({})

const Placeholder = styled.span({
  color: colors.grey
})

const IconBase = styled.span({
  display: 'inline-flex',
  fontSize: 24,
  color: colors.greyD1
})

interface Props {
  value?: string;
  placeholder?: string;
  onClick?: () => void
  isInvalid?: boolean
  size?: 'single' | 'double' | 'full'
}

export function DateInput ({ value = '', placeholder = '2022年01月23日', onClick, isInvalid, size = 'single' }:Props):ReactElement {
  return (
    <Base className={classNames(size && `-size-${size}`, isInvalid && '-invalid')} onClick={() => onClick?.()}>
      {value
        ? (
        <Value>{value}</Value>
          )
        : (
        <Placeholder>{placeholder}</Placeholder>
          )}
      <IconBase>
        <Icon iconName="calendar" />
      </IconBase>
    </Base>
  )
}
