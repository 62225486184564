import { DateTime } from 'luxon'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DateInput } from '../component/DateInput'
import { DatePicker } from '../component/DatePicker'
import { FieldError } from '../component/FieldError'
import { FormTable, FormTableHead, FormTableCell } from '../component/FormTable'
import { Icon } from '../component/Icon'
import { MessageBox } from '../component/MessageBox'
import { Modal } from '../component/Modal'
import { RadioButton, RadioButtonGroup } from '../component/RadioButton'
import { TextInput } from '../component/TextInput'
import { colors } from '../config/variables'
import { useAnalytics } from '../hooks/analytics'
import { useCalendar } from '../hooks/calendar'
import { useReservation, shopNames, ShopName, timeNames, TimeName, subjectNames, SubjectName } from '../hooks/reservation'

const Base = styled.div({
  display: 'block'
})

const Note = styled.p({
  margin: 0,
  padding: '24px 0',
  fontSize: 16,
  color: colors.black
})

const Caution = styled.span({
  color: colors.red
})

export function ReserveForm ():ReactElement {
  const [progress, setProgress] = useState<number>(0)
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [state, update, errors, validate, fetchToken, submit] = useReservation()
  const { trackEvent } = useAnalytics()

  const { holidays } = useCalendar()

  const scrollTop = () => {
    document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleConfirm = () => {
    if (validate()) {
      setProgress(1)
    } else {
      scrollTop()
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    submit()
      .then(() => {
        setProgress(2)
        trackEvent('submit_reservation')
      })
      .catch(() => {
        setProgress(3)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchToken()
  }, [])

  useEffect(() => {
    scrollTop()
  }, [progress])

  return (
    <Base>
      {progress === 0 && (
        <>
          <Note>
            必要事項を記入して「内容を確認」ボタンを押してください。
            <br />
            <Caution>
              ※ 当日の予約をご希望の方は、お電話にてお問い合わせください。
            </Caution>
          </Note>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="店舗" />
              <FormTableCell>
                <RadioButtonGroup>
                  <RadioButton
                    label="柏補聴器センター"
                    checked={state.shop === 'kashiwa'}
                    onChange={() => update('shop', 'kashiwa')}
                    onBlur={() => validate('shop')}
                  />
                  <RadioButton
                    label="松戸補聴器センター"
                    checked={state.shop === 'matsudo'}
                    onChange={() => update('shop', 'matsudo')}
                    onBlur={() => validate('shop')}
                  />
                </RadioButtonGroup>
                <FieldError message={errors.shop} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="No." optional />
              <FormTableCell>
                <TextInput
                  placeholder="0123456789"
                  value={state.id}
                  onChange={value => update('id', value)}
                  onBlur={() => validate('id')}
                  isInvalid={!!errors.id}
                />
                <FieldError message={errors.id} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="お名前" />
              <FormTableCell>
                <TextInput
                  placeholder="柏　太郎"
                  size="double"
                  value={state.name}
                  onChange={value => update('name', value)}
                  onBlur={() => validate('name')}
                  isInvalid={!!errors.name}
                />
                <FieldError message={errors.name} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="ご用件" />
              <FormTableCell>
                <RadioButtonGroup direction='column'>
                  {Object.keys(subjectNames).map(key => {
                    return (
                      <RadioButton
                        key={`subject-${key}`}
                        label={subjectNames[key as SubjectName]}
                        checked={state.subject === key}
                        onChange={() => update('subject', key)}
                        onBlur={() => validate('subject')}
                      />
                    )
                  })}
                </RadioButtonGroup>
                <FieldError message={errors.subject} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="メールアドレス" />
              <FormTableCell>
                <TextInput
                  placeholder="kashiwa@example.com"
                  size="double"
                  value={state.email}
                  onChange={value => update('email', value)}
                  onBlur={() => validate('email')}
                  isInvalid={!!errors.email}
                />
                <FieldError message={errors.email} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="電話番号" optional />
              <FormTableCell>
                <TextInput
                  placeholder="0471674133"
                  size="double"
                  value={state.phone}
                  onChange={value => update('phone', value)}
                  onBlur={() => validate('phone')}
                  isInvalid={!!errors.phone}
                />
                <FieldError message={errors.phone} />
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="予約日時" />
              <FormTableCell>
                <DateInput
                  onClick={() => setShowDatePicker(true)}
                  value={state.date ? DateTime.fromJSDate(state.date).toFormat('yyyy年MM月dd日') : undefined}
                  isInvalid={!!errors.date}
                />
                <FieldError message={errors.date} />
                <div style={{ marginTop: 16 }}>
                  <RadioButtonGroup>
                    <RadioButton
                      label="午前"
                      checked={state.time === 'am'}
                      onChange={() => update('time', 'am')}
                    />
                    <RadioButton
                      label="午後"
                      checked={state.time === 'pm'}
                      onChange={() => update('time', 'pm')}
                    />
                  </RadioButtonGroup>
                </div>
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="" />
              <FormTableCell>
                <button className="Button -size-small" onClick={handleConfirm}>
                  <Icon iconName="check" />
                  内容を確認
                </button>
              </FormTableCell>
            </tr>
          </FormTable>
          <Modal show={showDatePicker} onClose={() => setShowDatePicker(false)}>
            {showDatePicker && (
              <DatePicker
                value={state.date || undefined}
                holidays={holidays}
                onCancel={() => setShowDatePicker(false)}
                onSubmit={value => {
                  update('date', value)
                  setShowDatePicker(false)
                }}
              />
            )}
          </Modal>
        </>
      )}
      {progress === 1 && (
        <>
          <Note>
            ご記入内容を確認して、「送信」ボタンを押してください。
          </Note>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="店舗" />
              <FormTableCell confirm>
                {shopNames[state.shop as ShopName]}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="No." optional />
              <FormTableCell confirm>
                {state.id}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="お名前" />
              <FormTableCell confirm>
                {state.name}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="ご用件" />
              <FormTableCell confirm>
                {subjectNames[state.subject as SubjectName]}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="メールアドレス" />
              <FormTableCell confirm>
                {state.email}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="電話番号" optional />
              <FormTableCell confirm>
                {state.phone}
              </FormTableCell>
            </tr>
            <tr>
              <FormTableHead label="予約日時" />
              <FormTableCell confirm>
                {DateTime.fromJSDate(state.date as Date).toFormat('yyyy年MM月dd日')}
                {state.time && (
                  ` - ${timeNames[state.time as TimeName]}`
                )}
              </FormTableCell>
            </tr>
          </FormTable>
          <hr className="Divider" />
          <FormTable>
            <tr>
              <FormTableHead label="" />
              <FormTableCell>
                <div className="ButtonGroup">
                  <button className="Button -size-small" onClick={handleSubmit} disabled={loading}>
                    <Icon iconName="check" />
                    送信
                  </button>
                  <button className="Button -size-small -variant-grey" onClick={() => setProgress(0)}>
                    戻って編集する
                  </button>
                </div>
              </FormTableCell>
            </tr>
          </FormTable>
        </>
      )}
      {progress === 2 && (
        <MessageBox iconName="check">
          <div>
            ご予約リクエストを送信しました。
            まだ予約は確定しておりません。
            <br />
            補聴器センターからのメールまたは電話によるご案内をもって本予約といたします。
          </div>
          <a href="/" className="Button">トップページへ戻る</a>
        </MessageBox>
      )}
      {progress === 3 && (
        <MessageBox iconName="error" iconColor='red' backgroundColor='redL3'>
          <div>
            送信中にエラーが発生しました。
            <br />
            時間を置いて再度お試しいただくか、電話にてお問い合わせください。
          </div>
          <button className="Button -size-small" onClick={() => setProgress(0)}>再度送信する</button>
        </MessageBox>
      )}
    </Base>
  )
}
